<template>
	<div class="translate">
		<div class="content">
			<div class="title">机器翻译</div>
			<div class="center">
				<div class="center-left">
					<textarea
						placeholder="请输入要翻译的内容。"
						class="center-box"
						v-model="textValue"
					></textarea>
					<div class="select">
						<el-select
							v-model="languageValue"
							placeholder="中文->英文"
						>
							<el-option
								v-for="item in language"
								:key="item.value"
								:label="item.label"
								:value="item.value"
								@click.native="languageChange"
							>
							</el-option>
						</el-select>
					</div>
					<i
						class="iconfont icon-langdu"
						v-show="textValue && languageValue != 3"
						@click="aloud(textValue)"
						><span style="margin-left: 3px">朗读</span></i
					>
					<el-button
						class="btn"
						type="primary"
						:disabled="!textValue"
						@click="handTranslation"
						>翻译</el-button
					>
				</div>
				<div class="center-right">
					<textarea
						placeholder="这里是翻译的结果。"
						class="center-box"
						v-model="resultValue"
					></textarea>
					<img
						v-if="vsLoading"
						v-lazy="loading1Img"
						:key="loading1Img"
						alt=""
						class="vertical-middle"
					/>
					<div class="icon-btn" v-show="resultValue">
						<i class="iconfont icon-fuzhi" @click="textCopy()"
							><span style="margin-left: 3px">复制</span></i
						>
						<i
							class="iconfont icon-langdu"
							@click="aloud(resultValue)"
							v-if="languageValue == 0 || languageValue == 1"
							><span style="margin-left: 3px">朗读</span></i
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'translate',
	data() {
		return {
			loading1Img: window.imgUrl + '/loading1.gif',
			vsLoading: false,
			textValue: '',
			resultValue: '',
			languageValue: '',
			language: [
				{
					value: '0',
					label: '中文->英文',
				},
				{
					value: '1',
					label: '英文->中文',
				},
				{
					value: '2',
					label: '中文->维语',
				},
				{
					value: '3',
					label: '维语->中文',
				},
			],
			textAudio: null,
		}
	},
	watch: {},
	methods: {
		getlocal() {
			//找到锚点id
			let toElement = document.getElementById('experienceId')
			toElement.scrollIntoView()
		},
		handTranslation() {
			//点击翻译
			this.vsLoading = true
			const params = new FormData()
			params.append('type', this.languageValue)
			params.append('text', this.textValue)
			this.axios
				.request({
					url:
						'https://jet-voice.com/aliyun-jetcaswechat/' +
						'/wxreco/trans',
					method: 'post',
					data: params,
				})
				.then((res) => {
					if (res.data.code === 200) {
						this.vsLoading = false
						this.resultValue = res.data.results
					} else {
						this.$message.warning(res.data.msg)
					}
				})
				.catch(() => {
					this.$confirm('网络请求失败，请重试', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					})
						.then(() => {
							this.vsLoading = false
							this.$message({
								type: 'success',
								// message: "删除成功!",
							})
						})
						.catch(() => {
							this.vsLoading = false
							this.$message({
								type: 'info',
								// message: "已取消删除",
							})
						})
				})
		},
		aloud(content) {
			if (content) {
				this.startAudio(content)
			} else {
				this.stopAudio()
			}
		},
		//开始朗读
		startAudio(content) {
			if (this.textAudio) {
				this.stopAudio()
			}
			//创建一个 SpeechSynthesisUtterance的实例
			this.textAudio = new SpeechSynthesisUtterance()
			// 设置文本
			this.textAudio.text = content
			// 添加到队列
			this.textAudio.voiceURI = 'Google 普通话（中国大陆）'
			this.textAudio.rate = 1
			this.audioPlayStatus = true

			this.textAudio.onend = () => {
				this.stopAudio()
			}
			window.speechSynthesis.speak(this.textAudio)
		},
		stopAudio() {
			if (this.textAudio) {
				window.speechSynthesis.cancel()
				this.textAudio = null
			}
		},
		languageChange() {
			this.resultValue = ''
		},
		textCopy() {
			//点击复制
			// this.copyContent = '用户管理';//也可以直接写上等于你想要复制的内容
			let content = document.querySelector('.center-box')
			this.copyContent = content.innerText
			var input = document.createElement('input') // 直接构建input
			input.value = this.copyContent // 设置内容
			document.body.appendChild(input) // 添加临时实例
			input.select() // 选择实例内容
			document.execCommand('Copy') // 执行复制
			document.body.removeChild(input) // 删除临时实例
			// this.$message.success("内容已复制到剪切板！");
			this.$message({
				message: '内容已复制到剪切板！',
				iconClass: 'iconfont',
			})
		},
	},
	components: {},
	computed: {},
	created() {
		//创建时执行跳转锚点位置
		this.$nextTick(() => {
			this.getlocal()
		})
	},
	mounted() {
		this.languageValue = this.language[0].value
	},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.translate {
	.content {
		width: 100%;
		.title {
			font-size: 0.28rem;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 700;
			color: #127bf2;
			line-height: 0.36rem;
			margin-bottom: 0.2rem;
		}
		.center {
			width: 100%;
			// min-height: 700px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			.center-left {
				width: 50%;
				height: 6.4rem;
				position: relative;
				.center-box {
					width: 100%;
					height: 100%;

					border: 0;
					padding: 0.4rem 0.4rem 0.8rem 0.4rem;
					box-sizing: border-box;
					background-color: #f8fafd;
					border-radius: 0.1rem 0 0 0.1rem;
					font-size: 0.2rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					color: #acb7c4;
					line-height: 0.28rem;

					resize: none;
					overflow-x: hidden;
					&:focus {
						outline: none !important;
						border: 1px solid #127bf2;
					}
				}
				.select {
					position: absolute;
					left: 0.2rem;
					bottom: 0.2rem;
					.el-select {
						width: 1.2rem;
						height: 0.4rem;
						::v-deep .el-input__inner {
							height: 0.4rem;
							border-color: #dcdfe6;
						}
					}
				}
				.iconfont {
					width: 0.6rem;
					height: 0.4rem;
					text-align: center;
					line-height: 0.4rem;
					font-size: 14px;
					position: absolute;
					right: 1.52rem;
					bottom: 0.2rem;
					color: #fff;
					background: #127bf2;
					border-radius: 0.05rem;
					cursor: pointer;
				}
				.btn {
					width: 1.2rem;
					height: 0.4rem;
					font-size: 0.14rem;
					position: absolute;
					right: 0.2rem;
					bottom: 0.2rem;
					background: #127bf2;
					border-radius: 0.05rem;
					border: none;
				}
			}
			.center-right {
				width: 50%;
				height: 6.4rem;
				position: relative;
				.center-box {
					width: 100%;
					height: 100%;
					font-size: 0.2rem;
					border: 0;
					padding: 0.4rem 0.4rem 0.8rem 0.4rem;
					box-sizing: border-box;
					border-radius: 0 0.1rem 0.1rem 0;
					background-color: #eceff3;
					resize: none;
					overflow-x: hidden;
					&:focus {
						outline: none !important;
						border: 1px solid #127bf2;
					}
				}
				.vertical-middle {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					margin: auto;
					width: 0.3rem;
					height: 0.3rem;
				}
				.icon-btn {
					font-size: 0.18rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					line-height: 0.23rem;
					color: #666666;
					position: absolute;
					left: 0.2rem;
					bottom: 0.2rem;
					display: flex;
					justify-content: center;
					i:nth-child(1) {
						margin-left: 0.1rem;
						cursor: pointer;
						&:hover {
							color: #127bf2;
						}
					}
					i:nth-child(2) {
						margin-left: 0.2rem;
						cursor: pointer;
						&:hover {
							color: #127bf2;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) {
	.translate .content .center {
		.center-left {
			width: 100%;
			.center-box {
				border-radius: 10px;
			}
		}
		.center-right {
			width: 100%;
			margin-bottom: 40px;
			.center-box {
				border-radius: 10px;
			}
		}
	}
}
</style>
